import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import useNotifications from '@/composables/useNotifications'
import { queryComplaintsData } from '@/@core/queries/complaints'
// import { updateImprovement } from '@/@core/queries/improvements'
import i18n from '@/libs/i18n'
import useCommon from '@/views/organization/useCommon'
import axios from '@axios'

export default function useComplaintList() {
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { handleError } = useCommon()
  const { client, worker_id } = store.state?.userStore?.userData
  const { default_language } = JSON.parse(localStorage.getItem('clientData') || '{}')
  const refComplaintTable = ref(null)
  const complaints = ref([])
  const complaintStats = ref({})
  const perPage = ref(10)
  const totalComplaints = ref(0)
  const currentPage = ref(1)
  const searchQuery = ref('')
  const sortBy = ref('createdAt')
  const isSortDirDesc = ref(true)
  const isLoading = ref(true)
  const workerFilter = ref([])
  const activeState = ref('total')
  const activeStage = ref(null)
  const pendingTasksFilter = ref(false)
  const statesData = ref({})
  const stagesData = ref({})
  const clearFiltersDisabled = computed(() => !searchQuery.value && activeState.value === 'total' && !activeStage.value && !pendingTasksFilter.value)

  // Table Handlers
  const tableColumns = computed(() => {
    return [
      {
        key: 'themes',
        label: i18n.t('label.themes'),
        sortable: true,
      },
      {
        key: 'caseNumber',
        label: i18n.t('message.case_number'),
        sortable: true,
      },
      {
        key: 'dueDate',
        label: i18n.t('label.due_date'),
        sortable: true,
      },
      {
        key: 'stage',
        label: i18n.t('improvement_opp.stage'),
        sortable: true,
      },
      {
        key: 'state',
        label: i18n.t('improvement_opp.state'),
        sortable: true,
      },
      {
        key: 'Actions',
        label: i18n.t('message.tableHeader.actions'),
      },
    ]
  })

  const fetchComplaints = () => {
    isLoading.value = true

    const input = {
      client_id: client.$oid,
      // workers: workerFilter.value,
      search: searchQuery.value,
      sortBy: sortBy.value || '_id',
      sortOrder: isSortDirDesc.value ? -1 : 1,
      limit: perPage.value,
      offset: (currentPage.value * perPage.value) - perPage.value,
      state: activeState.value,
      stage: activeStage.value,
      onlyPendingTasks: pendingTasksFilter.value,
      workerId: worker_id?.$oid
    }

    axios
      .post('/graphql', {
        query: queryComplaintsData,
        variables: { input },
      })
      .then(({ data }) => {
        if (data.errors) throw new Error(data.errors[0].message)

        const { ComplaintsData: { complaintsPaginated, complaintsStats } } = data.data || {}

        complaintsPaginated.forEach(e => {
          // Format date
          const date = e.dueDate ? new Date(e.dueDate) : undefined
          e.dueDate = date?.toLocaleDateString(`${default_language || 'en'}-US`) || ''

          // Set themes
          e.themesComputed = computed(() => e.themes?.map(theme => theme.isNewOption ? theme.value : i18n.t(`complaintThemes.${theme.value}`)).join(" / ")),

          // Set state
          e.stateComputed = computed(() => i18n.t(e.state))

          // Set stage
          e.stageComputed = computed(() => e.stage ? i18n.t(`caseStages.${e.stage}`) : '')
        })

        complaints.value = complaintsPaginated
        complaintStats.value = complaintsStats.stateCounts.statsForCards
        totalComplaints.value = complaintsStats.totalCount
        statesData.value = {
          labels: complaintsStats.stateCounts.labels,
          values: complaintsStats.stateCounts.values,
          total: complaintsStats.totalCount
        }
        stagesData.value = {
          labels: complaintsStats.stageCounts.labels,
          values: complaintsStats.stageCounts.values,
          total: complaintsStats.totalCount
        }
      })
      .catch(error => {
        console.log(error)
        handleError({ error, defaultMessage: i18n.t('message.err_complaints_list') })
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  const deleteComplaint = (id) => {
    // axios
    //   .post('/graphql', {
    //     query: updateImprovement,
    //     variables: { query: { _id: id }, data: { deleted: true } },
    //   })
    //   .then(({ data }) => {
    //     if (data.errors) throw new Error(data.errors[0].message)
    //     showSuccessMessage(i18n.t('message.improvement_remove'))
    //     fetchComplaints()
    //   })
    //   .catch(error => {
    //     console.log(error)
    //     showErrorMessage(i18n.t('message.improvement_remove_error'))
    //   })
  }

  const updateFilter = (data) => {
    workerFilter.value = data.workerFilter

    checkPageAndFetch()
  }

  const filterByStage = (stage) => {
    activeStage.value = activeStage.value === stage ? null : stage
    
    checkPageAndFetch()
  }

  const filterByState = (state, origin) => {
    if (activeState.value === state) {
      if (origin === 'fromChart') state = 'total'
      else return
    }
    activeState.value = state

    checkPageAndFetch()
  }

  const clearFilters = () => {
    const fetchData = !searchQuery.value && !pendingTasksFilter.value
    
    searchQuery.value = ''
    activeState.value = 'total'
    activeStage.value = null
    pendingTasksFilter.value = false

    if (!fetchData) return    // This is to avoid duplicate data fetches

    checkPageAndFetch()
  }

  const checkPageAndFetch = () => {
    if (currentPage.value === 1) fetchComplaints()
    else currentPage.value = 1
  }

  watch([currentPage], () => {
    fetchComplaints()
  })

  watch([searchQuery, sortBy, isSortDirDesc, perPage, pendingTasksFilter], () => {
    checkPageAndFetch()
  })

  return {
    fetchComplaints,
    tableColumns,
    perPage,
    currentPage,
    totalComplaints,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refComplaintTable,
    complaints,
    deleteComplaint,
    workerFilter,
    isLoading,
    complaintStats,
    updateFilter,
    filterByState,
    filterByStage,
    activeState,
    pendingTasksFilter,
    statesData,
    stagesData,
    clearFilters,
    clearFiltersDisabled,
  }
}

<template>
  <div>
    <b-row>
      <b-col md="6">
        <donut-chart-state
          v-if="statesData.labels && statesData.labels.length"
          :data="statesData"
          i18nTitleVariable="complaintsByState"
          @filterByState="filterByState"
        />
      </b-col>
      <b-col md="6">
        <donut-chart-stage
          v-if="stagesData.labels && stagesData.labels.length"
          :data="stagesData"
          i18nTitleVariable="complaintsByStage"
          @filterByStage="filterByStage"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="3">
        <statistic-card-vertical :statistic="totalComplaints || 0" variant="info" iconName="ListIcon" i18nVariable="message.total" :isButton="true" :isSelected="activeState === 'total'" @filter="filterByState('total')"></statistic-card-vertical>
      </b-col>
      <b-col sm="3">
        <statistic-card-vertical :statistic="complaintStats.completed || 0" variant="success" iconName="CheckIcon" i18nVariable="completed_all_m" :isButton="true" :isSelected="activeState === 'completed'" @filter="filterByState('completed')"></statistic-card-vertical>
      </b-col>
      <b-col sm="3">
        <statistic-card-vertical :statistic="complaintStats.upToDate || 0" variant="primary" iconName="XIcon" i18nVariable="upToDate" :isButton="true" :isSelected="activeState === 'upToDate'" @filter="filterByState('upToDate')"></statistic-card-vertical>
      </b-col>
      <b-col sm="3">
        <statistic-card-vertical :statistic="complaintStats.delayed || 0" variant="danger" iconName="ClockIcon" i18nVariable="delayed_all_m" :isButton="true" :isSelected="activeState === 'delayed'" @filter="filterByState('delayed')"></statistic-card-vertical>
      </b-col>
    </b-row>

    <b-card>
      <b-row class="mb-2">  
        <b-col md="4" class="d-flex align-items-center">
          <b-form-checkbox
            id="checkbox-2"
            v-model="pendingTasksFilter"
            name="checkbox-2"
            :value="true"
            :unchecked-value="false"
          >
            {{ $t('label.filterByPendingTasks') }}
          </b-form-checkbox>
        </b-col>

        <b-col md="4">
          <b-button
            variant="outline-secondary"
            :disabled="clearFiltersDisabled"
            @click="clearFilters"
          >
            <feather-icon
              icon="TrashIcon"
              class="mr-50"
            />
            {{ $t('clear_filters') }}
          </b-button>
        </b-col>

        <b-col md="4" class="d-flex align-items-center">
          <b-form-input
            v-model="searchQuery"
            :placeholder="$t('message.search')"
            type="search"
            class="d-inline-block"
          />
        </b-col>
  
        <!-- <b-col>
          <filters 
            :workerFilterShow="true"
            @changeFilter="updateFilter"
          />
        </b-col> -->
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-table
            ref="refComplaintTable"
            :items="complaints"
            hover
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty=""
            :empty-text="isLoading ? $t('message.loading') : $t('message.no_records')"
            :sort-desc.sync="isSortDirDesc"
          >
            <template #cell(themes)="data">
              <span>
                {{ data.item.themesComputed.value }}
              </span>
            </template>

            <template #cell(stage)="data">
              <span>
                {{ data.item.stageComputed.value }}
              </span>
            </template>

            <template #cell(state)="data">
              <span :class="`font-weight-bold ${data.item.state === 'completed' ? 'text-success' : data.item.state === 'delayed' ? 'text-danger' : 'text-primary'}`">
                {{ data.item.stateComputed.value }}
              </span>
              <span v-if="data.item.state === 'completed' && data.item.isCompletedOverdue" class="font-weight-bold text-danger">
                 ({{ $t('delayed') }})
              </span>
            </template>
  
            <template #cell(actions)="data">
              <div class="flex flex-column">
                <EditButton
                  @clicked="router.push({name: 'organization-complaint-edit', params: { id: data.item._id }})"
                  i18nHoverVariable="Execute"
                />
                <!-- <DeleteButton v-b-modal="`modal ${data.item._id}`"/>
                <b-modal
                  :id="`modal ${data.item._id}`"
                  centered
                  no-close-on-backdrop
                  modal-class="modal-danger"
                  ok-variant="danger"
                  cancel-variant="outline-secondary"
                  :title="$t('message.confirm_action')"
                  :ok-title="$t('message.delete')"
                  :cancel-title="$t('message.cancel')"
                  @ok="deleteComplaint(data.item._id)"
                >
                  {{ $t('message.confirm_delete_improvement') }}
                </b-modal> -->
              </div>
            </template>
          </b-table>
        </b-col>
  
        <b-col
          cols="12"
          class="d-flex justify-content-between flex-wrap"
        >
          <div class="mb-1">
            <b-form-group class="mb-0">
              <label class="d-inline-block text-sm-left mr-50">{{ $t('message.per_page') }}</label>
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="[5, 10, 15, 20, 25, 30, 40, 50, 100]"
                class="w-50"
              />
            </b-form-group>
          </div>
  
          <div class="mb-1">
            <span class="text-muted">{{ $tc('message.paginationText', 0, { from: `${((currentPage * perPage) - perPage) + (complaints.length ? 1 : 0)}`, to: `${complaints.length + ((currentPage * perPage) - perPage)}`, total: `${totalComplaints}` }) }}</span>
          </div>
  
          <b-pagination
            v-model="currentPage"
            :total-rows="totalComplaints"
            :per-page="perPage"
            first-number=""
            last-number=""
            prev-class="prev-item"
            next-class="next-item"
          />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { BBadge, BPagination, BTable, BModal, VBModal, BFormCheckbox } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { onMounted } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import EditButton from '@/views/components/Shared/Buttons/EditButton.vue'
// import DeleteButton from '@/views/components/Shared/Buttons/DeleteButton.vue'
import useComplaintList from './useComplaintList'
import Filters from "@/views/organization/Filters.vue";
import StatisticCardVertical from '@/views/habit/StatisticCardVertical.vue'
import DonutChartState from '@/views/organization/case/list/charts/ApexDonutChartState.vue'
import DonutChartStage from '@/views/organization/case/list/charts/ApexDonutChartStage.vue'

export default {
  components: {
    BTable,
    BBadge,
    BPagination,
    EditButton,
    // DeleteButton,
    vSelect,
    Filters,
    BModal,
    StatisticCardVertical,
    BFormCheckbox,
    DonutChartState,
    DonutChartStage,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      items: [],
    }
  },
  setup() {
    const { route, router } = useRouter()
    const {
      fetchComplaints,
      tableColumns,
      perPage,
      currentPage,
      totalComplaints,
      searchQuery,
      sortBy,
      isSortDirDesc,
      deleteComplaint,
      refComplaintTable,
      complaints,
      isLoading,
      complaintStats,
      updateFilter,
      filterByState,
      filterByStage,
      activeState,
      pendingTasksFilter,
      statesData,
      stagesData,
      clearFilters,
      clearFiltersDisabled,
    } = useComplaintList()

    onMounted(async () => {
      if (route.value.query.state) {
        activeState.value = route.value.query.state
      }
      
      fetchComplaints()
    })

    return {
      router,
      fetchComplaints,
      tableColumns,
      perPage,
      currentPage,
      totalComplaints,
      searchQuery,
      sortBy,
      isSortDirDesc,
      deleteComplaint,
      refComplaintTable,
      complaints,
      updateFilter,
      isLoading,
      complaintStats,
      filterByState,
      filterByStage,
      activeState,
      pendingTasksFilter,
      statesData,
      stagesData,
      clearFilters,
      clearFiltersDisabled,
    }
  },
}
</script>

<style lang="scss" scoped>
.form-group {
  label {
    font-size: 0.85rem;
    font-weight: 400;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
